import axios from "../axios";
import * as Sentry from "@sentry/vue";
const snackbar = {
  namespaced: true,

  state: () => ({
    package: null,
    createOrder: false,
    tickets: [],
    ticketUpdated: false,
    userTicket: null,
    courseTicketsLinks: [],
    courseTicketsLinksUpdated: false,
  }),

  mutations: {
    SET_PACKAGE(state, load) {
      state.package = load;
    },
    CREATE_ORDER(state, success) {
      state.createOrder = success;
    },
    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },
    SET_TICKET_UDPATED(state, status) {
      state.ticketUpdated = status;
    },
    SET_USER_TICKET(state, ticket) {
      state.userTicket = ticket;
    },
    SET_COURSE_TICKETS_LINKS(state, links) {
      state.courseTicketsLinks = links;
    },
    SET_TICKET_LINK_UPDATED(state, status) {
      state.courseTicketsLinksUpdated = status;
    },
  },

  actions: {
    async handleClickedLink({ commit }, id) {
      try {
        const url = `courseTicketsLinks/${id}/clicked`;
        await axios.put(url);
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },
    async fetchCourseTicketsLinks({ commit }) {
      try {
        const url = `courseTicketsLinks`;
        const response = await axios.get(url);
        commit("SET_COURSE_TICKETS_LINKS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_COURSE_TICKETS_LINKS", []);
      }
    },
    async updateCourseTicketsLinks({ commit }, data) {
      try {
        const { id, link, name } = data;
        const url = `courseTicketsLinks/${id}`;
        await axios.put(url, { link, name });
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },
    async createCourseTicketsLinks({ commit }, data) {
      try {
        const { link, name } = data;
        const url = `courseTicketsLinks`;
        await axios.post(url, { link, name });
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },

    async deleteCourseTicketsLinks({ commit }, id) {
      try {
        const url = `courseTicketsLinks/${id}`;
        await axios.delete(url);
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },
    async fetchListOfTickets({ commit }) {
      try {
        const url = `courseTickets/users`;
        const response = await axios.get(url);
        commit("SET_TICKETS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_TICKETS", []);
      }
    },
    async fetchPackage({ commit }, id) {
      try {
        const response = await axios.get(`courseTickets/${id}`);
        commit("SET_PACKAGE", response.data);
      } catch (err) {
        commit("SET_PACKAGE", null);
        console.log(err);
      }
    },

    async createOrder({ commit }, data) {
      const {
        phone,
        name,
        email,
        discountCode,
        packageId,
        newsletter,
        companyName,
        nip,
        address,
        invoiceForCompany,
      } = data;
      return axios
        .post(`courseTickets/orders/${packageId}`, {
          name,
          email,
          phone,
          email_confirmation: email,
          userDiscountCode: discountCode,
          newsletter,
          address,
          companyName,
          nip,
          invoiceForCompany,
        })
        .then(function(response) {
          const { data } = response;
          commit("CREATE_ORDER", true);
          return data;
        })
        .catch((error) => {
          console.log(error);
          commit("CREATE_ORDER", false);
          Sentry.captureException(error);
        });
    },

    async addValueToTicket({ commit }, data) {
      try {
        const { email } = data;
        const url = `courseTickets/users/action`;
        await axios.put(url, { action: "ADD", email });
        commit("SET_TICKET_UDPATED", true);
      } catch (error) {
        commit("SET_TICKET_UDPATED", false);
        console.log(error);
      }
    },
    async minusValueToTicket({ commit }, data) {
      try {
        const { email } = data;
        const url = `courseTickets/users/action`;
        await axios.put(url, { action: "SUBTRACT", email });
        commit("SET_TICKET_UDPATED", true);
      } catch (error) {
        commit("SET_TICKET_UDPATED", false);
        console.log(error);
      }
    },
    async changeExpirationTimeForTicket({ commit, dispatch }, data) {
      try {
        const { date, email } = data;
        const url = `courseTickets/users/expirationTime`;
        await axios.put(url, { date, email });
        commit("SET_TICKET_UDPATED", true);
        await dispatch("fetchListOfTickets");
      } catch (error) {
        commit("SET_TICKET_UDPATED", false);
        console.log(error);
      }
    },
    async getUserTicketDetails({ commit }) {
      try {
        const url = `courseTickets/my`;
        const response = await axios.get(url);
        commit("SET_USER_TICKET", response.data);
      } catch (error) {
        commit("SET_USER_TICKET", null);
        console.log(error);
      }
    },
  },

  getters: {
    getPackage: (state) => state.package,
    getTickets: (state) => state.tickets,
    getUserTicket: (state) => state.userTicket,
    getCoursesTicketsLinks: (state) => state.courseTicketsLinks,
  },
};

export default snackbar;
