<template>
  <div style="margin-top: 0.5rem; padding: 0">
    <span v-if="maxLength"
      ><b>Maksymalna liczba znaków</b>: {{ maxLength }}</span
    >
    <p><b>Liczba znaków</b>: {{ content.length }}</p>
    <quill-editor
      ref="myQuillEditor"
      @change="onEditorChange($event)"
      style="width: 100%; margin-bottom: 1rem; padding: 0"
      :content="content"
      :id="editorId"
    >
    </quill-editor>
  </div>
</template>
<script>
export default {
  props: ["startContent", "editorId", "maxLength"],
  data() {
    return {
      content: "",
      replacedImages: [],
    };
  },
  created() {
    if (this.startContent.length > 0) this.content = this.startContent;
  },

  watch: {
    startContent() {
      this.content = this.startContent;
    },
  },

  methods: {
    uploadImage() {},
    async onEditorChange({ html }) {
      if (html !== null && html !== undefined) {
        let htmlWithReplacedCharacters = html;
        if (html) {
          htmlWithReplacedCharacters = html
            .replaceAll(`class="ql-align-center"`, `style="text-align: center"`)
            .replaceAll(
              `class="ql-align-justify"`,
              `style="text-align: justify"`
            )
            .replaceAll(`class="ql-size-huge"`, `style="font-size: 32px;"`)
            .replaceAll(`class="ql-size-large"`, `style="font-size: 19px;"`)
            .replaceAll(`class="ql-size-small"`, `style="font-size: 10px;"`)

            .replaceAll(`class="ql-align-right"`, `style="text-align: right"`);

          this.replacedImages.forEach((image) => {
            htmlWithReplacedCharacters = htmlWithReplacedCharacters.replace(
              image.base64,
              image.imageUrl
            );
          });
          const imageRegex = /<img src="data:image\/png;base64,[^"]*">/;

          const base64StringRegex =
            /<img src="data:image\/png;base64,([^"]*)">/;
          const matchedBase64 =
            htmlWithReplacedCharacters.match(base64StringRegex);
          if (matchedBase64) {
            const base64String = matchedBase64[1];
            const start = matchedBase64[0].substring(0, 40);

            let extension = "";
            if (start.includes("png")) {
              extension = "png";
            } else if (start.includes("jpeg") || start.includes("jpg")) {
              extension = "jpg";
            } else if (start.includes("gif")) {
              extension = "gif";
            } else if (start.includes("svg")) {
              extension = "svg";
            }
            const url = await this.$store.dispatch(
              "app/getImageUrlFromBase64",
              {
                base64: base64String,
                extension,
              }
            );
            htmlWithReplacedCharacters = htmlWithReplacedCharacters.replace(
              imageRegex,
              `<img src="${url}" />`
            );
            this.replacedImages.push({
              base64: matchedBase64[0],
              imageUrl: `<img src="${url}" />`,
            });
          }
        }

        this.$emit("contentchanged", htmlWithReplacedCharacters);
        this.content = html;
      }
    },
  },

  computed: {
    editor() {
      if (this.$refs.myQuillEditor) {
        return this.$refs.myQuillEditor.quill;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.a {
  text-align: right;
}
</style>
