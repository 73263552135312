var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-container"},[(
      _vm.currentBreakpointName && _vm.currentBreakpointName !== 'xs' && !_vm.isMobile
    )?_c('div',{staticClass:"left-side"},[_vm._m(0)]):_vm._e(),(!_vm.forgotPass)?_c('div',{staticClass:"right-side"},[_c('h1',[_vm._v("Witam")]),_c('div',{staticClass:"register-buttons"},[_c('a',{staticClass:"login",attrs:{"href":"#"},on:{"click":_vm.clickOnLog}},[_c('span',{staticClass:"logbtn",class:{ active: _vm.isLogin }},[_vm._v("Zaloguj się")])]),_c('span',{staticClass:"or"},[_vm._v("albo")]),_c('a',{staticClass:"register",attrs:{"href":"#"},on:{"click":_vm.clickOnReg}},[_c('span',{staticClass:"logbtn",class:{ active: !_vm.isLogin }},[_vm._v("Zarejestruj się")])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"none"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('div',{staticClass:"flexit"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.email),expression:"formData.email",modifiers:{"trim":true}}],attrs:{"type":"text","autocomplete":"none","placeholder":"Wpisz swój adres email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors[0])?_c('h5',[_vm._v("Niepoprawny e-mail")]):_vm._e()])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('div',{staticClass:"flexit"},[_c('label',{attrs:{"for":"haslo"}},[_vm._v("Hasło")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],attrs:{"type":"password","autocomplete":"none","placeholder":"Wpisz hasło"},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}}),(errors[0])?_c('h5',[_vm._v("To pole nie może być puste!")]):_vm._e()])]}}],null,true)}),(!_vm.isLogin)?_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('div',{staticClass:"flexit"},[_c('label',{attrs:{"for":"confirm"}},[_vm._v("Powtórz hasło")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.confirm),expression:"formData.confirm"}],attrs:{"type":"password","autocomplete":"none","placeholder":"Powtórz hasło"},domProps:{"value":(_vm.formData.confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "confirm", $event.target.value)}}}),(errors[0])?_c('h5',[_vm._v("Niezgodność haseł")]):_vm._e()])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"buttons"},[(_vm.isLogin)?_c('button',{staticClass:"background",attrs:{"type":"submit"}},[_vm._v(" Zaloguj się ")]):_c('button',{staticClass:"background",attrs:{"type":"submit"}},[_vm._v(" Zarejestruj się ")]),(_vm.isLogin)?_c('p',{staticClass:"remind-password",on:{"click":function($event){_vm.forgotPass = true}}},[_vm._v(" Przypomnij hasło ")]):_vm._e()])],1)]}}],null,false,3622145538)})],1):_c('div',{staticClass:"right-side w-50"},[_c('h1',[_vm._v("Zmień hasło")]),_c('i',{staticClass:"fas fa-arrow-left fa-2x",on:{"click":function($event){_vm.forgotPass = false}}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"none"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('div',{staticClass:"flexit"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","autocomplete":"none","placeholder":"Wpisz swój adres email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),(errors[0] && _vm.formData.email !== '')?_c('h5',[_vm._v(" Niepoprawny e-mail ")]):_vm._e()])]}}],null,true)}),(_vm.isLogin)?_c('button',{staticClass:"btn-background",attrs:{"type":"submit"}},[_vm._v(" Zresetuj hasło ")]):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo_text"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/dagajoga-logo.png"),"alt":"\n        uważna praktyka,\n        pranajama oddech,\n        Nauli,\n        kręgosłup,\n        subskrypcja DagaJoga\n        szkoła online,\n        filmy jogi,\n        joga zdrowy,\n        joga video,\n        prana vyavama,\n        praktyka\n        ","loading":"lazy"}})])}]

export { render, staticRenderFns }