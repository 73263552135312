import axios from "../axios";
const app = {
  namespaced: true,
  state: () => ({
    screenBreakpoint: "",
    status: false,
  }),
  mutations: {
    SET_SCREEN_BREAKPOINT(state, breakpoint) {
      state.screenBreakpoint = breakpoint;
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
  },
  actions: {
    setScreenBreakpoint({ commit }, breakpoint) {
      commit("SET_SCREEN_BREAKPOINT", breakpoint);
    },

    async getImageUrlFromBase64({ commit }, object) {
      const { base64, extension } = object;
      const url = `management/files`;
      const response = await axios.post(url, { base64, extension });
      commit("SET_STATUS", true);
      return response.data;
    },
  },

  getters: {
    getScreenBreakpoint: (state) => state.screenBreakpoint,
  },
};

export default app;
